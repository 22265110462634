@import '../node_modules/@sagra/sagra-styles/lib/styles/variables-global.scss';


html body app-root {
    height: 100%;
    width: 100%;
    display: block;
}

.rowW {
    background-color: $color-white !important;
    height:60px;
}
